import { getRelationship, Organization, Security } from "~/src/api";
import type { Permission, OrganizationEntity } from "~/src/api";

export function useUserOrgsWithPerms(
  ...permissions: (Permission | undefined)[]
) {
  const orderedPerms = permissions.filter(onlyTruthys).sort();
  return useState(`orgs-permissions-${orderedPerms.join(",")}`, () =>
    getOrgsByPermission(...orderedPerms),
  );
}

export type UseUserMemberOwnerOrgsReturn = {
  orgs: Ref<OrganizationEntity[]>;
  loading: ComputedRef<boolean>;
  refresh: () => Promise<void>;
};

export function useUserMemberOwnerOrgs() {
  return useNonReactiveState<UseUserMemberOwnerOrgsReturn>(
    "user-member-owner-orgs",
    () => {
      const loggedUser = useLoggedUser();
      const orgs = ref<OrganizationEntity[]>([]);

      const { status, data, refresh } = useAsyncData(
        `user-member-owner-orgs`,
        async () => {
          if (!loggedUser.value) return null;

          const [ownedOrgs, memberOrgs] = await Promise.all([
            Organization.search({ ownerId: loggedUser.value.id }),
            Organization.search({ memberId: loggedUser.value.id }),
          ]);

          return [...ownedOrgs.data, ...memberOrgs.data].dedupe((o) => o.id);
        },
        { watch: [loggedUser] },
      );

      watch(data, (newData) => {
        if (newData) orgs.value = newData;
      });

      // Refresh every minute
      setInterval(refresh, 1000 * 60 * 1);

      return {
        orgs,
        loading: computed(() => status.value === "pending"),
        refresh,
      };
    },
  );
}

// assume ordering of perms here
function getOrgsByPermission(...permissions: Permission[]) {
  if (!permissions.length) return getUserOrgs();

  const { data: userOrgs, execute } = getUserOrgs();
  return useAsyncData(
    `orgs-permissions-${permissions.join(",")}`,
    async () => {
      const userId = useNuxtApp().$auth()?.user?.profile.sub;
      if (!userId) throw new Error("Not logged in.");

      await execute();

      const orgsUserDoesNotOwn = userOrgs.value.filter(
        (org) => getRelationship(org, "user").id !== userId,
      );
      const orgsUserOwns = userOrgs.value.filter(
        (org) => getRelationship(org, "user").id === userId,
      );
      const satisfyingOrgs: OrganizationEntity[] = [];
      for (const org of orgsUserDoesNotOwn) {
        const { data: perms, execute } = getUserPermsForOrg(org.id).value;
        await execute();
        const grantedPerms = perms.value?.data
          .filter((perm) => perm.attributes.isGranted)
          .map((perm) => perm.attributes.permission);
        if (permissions.some((perm) => !grantedPerms?.includes(perm))) continue;
        satisfyingOrgs.push(org);
      }
      return [...orgsUserOwns, ...satisfyingOrgs];
    },
    { default: () => [] },
  );
}

function getUserPermsForOrg(id: string) {
  const nuxtApp = useNuxtApp();
  return useState(`user-org-permissions-${id}`, () =>
    useAsyncData(
      `user-org-permissions-${id}`,
      async () => {
        const user = nuxtApp.$auth()?.user;
        if (!user) throw new Error("Not logged in.");
        return Security.getSpecificGroupMemberPermissions(
          id,
          user.profile.sub,
          await getTokenOrThrow(),
        );
      },
      { immediate: false },
    ),
  );
}

function getUserOrgs() {
  const nuxtApp = useNuxtApp();
  const { data: cachedUserOrgs } = useNuxtData<
    OrganizationEntity[] | undefined
  >("user-orgs");
  return useAsyncData(
    `user-orgs`,
    async () => {
      if (cachedUserOrgs.value?.length) return cachedUserOrgs.value;
      const user = nuxtApp.$auth()?.user;
      if (!user) throw new Error("Not logged in.");

      const [ownedOrgs, memberOrgs] = await Promise.all([
        Organization.search({ ownerId: user.profile.sub }),
        Organization.search({ memberId: user.profile.sub }),
      ]);

      const all: OrganizationEntity[] = ownedOrgs.data.concat(memberOrgs.data);
      const unique = [...new Set(all.map((x) => x.id))];

      return unique.map((id) => all.find((org) => org.id === id)!);
    },
    { immediate: false, default: () => [] as OrganizationEntity[] },
  );
}
