const stateMap = new Map<string, any>();

/**
 * Get a single non-reactive state value by key, initializing it if it doesn't exist.
 */
export function useNonReactiveState<T>(key: string, initializer: () => T): T {
  let value = stateMap.get(key);
  if (!value) {
    value = initializer();
    stateMap.set(key, value);
  }
  return value;
}
